@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html {
    font-family: 'DM Sans', sans-serif !important;
    font-feature-settings: 'kern' !important;
    -webkit-font-smoothing: antialiased;
    letter-spacing: -0.5px;
  }
}

input.defaultCheckbox::before {
  content: url(../src/assets/svg/checked.svg);
  color: white;
  opacity: 0;
  height: 18px;
  width: 17px;
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0px);
}

input:checked.defaultCheckbox::before {
  opacity: 1;
}

.quill .ql-container.ql-snow {
  border: none
}

.quill .ql-toolbar.ql-snow {
  border: none;
  border-bottom: 1px solid #e9ecef;
}

.quill .ql-editor.ql-blank::before {
  font-style: normal;
  font-size: 0.875rem;
  line-height: 1.25rem;
  font-weight: 400;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  color: inherit;
}

.custom-datepicker-style .react-datepicker__input-container {
  font-size: 0.875rem;
  line-height: 1.25rem;
  padding: 0.75rem;
  border: 1px solid rgb(233 236 239);
  border-radius: 0.75rem;
  width: 100%;
  height: 3rem;
}

.custom-datepicker-style .react-datepicker__input-container input:focus {
  outline: 2px solid transparent;
  outline-offset: 2px;
}

.custom-datepicker-style .react-datepicker__view-calendar-icon input {
  width: 100%;
  padding: 3px 10px 5px 35px;
  background: transparent;
}

.custom-datepicker-style .react-datepicker__input-container .react-datepicker__calendar-icon {
  padding: 0.25rem 0.35rem;
}

.react-datepicker-popper .react-datepicker__month-container .react-datepicker__day.react-datepicker__day--disabled {
  cursor: not-allowed;
}

.react-datepicker-popper .react-datepicker__month-container .react-datepicker__day.react-datepicker__day--disabled:hover {
  background-color: white;
  color: #ccc;
}

.react-datepicker-popper .react-datepicker__month-container .react-datepicker__day.react-datepicker__day--disabled.react-datepicker__day--in-range {
  background-color: #cbd5e0;
  color: gray;
}

.react-datepicker-popper .react-datepicker__month-container .react-datepicker__day.react-datepicker__day--in-selecting-range,
.react-datepicker-popper .react-datepicker__month-container .react-datepicker__day.react-datepicker__day--keyboard-selected,
.react-datepicker-popper .react-datepicker__month-container .react-datepicker__day:hover {
  background-color: #f5ac63;
  color: white;
}

.react-datepicker-popper .react-datepicker__month-container .react-datepicker__day.react-datepicker__day--selected,
.react-datepicker-popper .react-datepicker__month-container .react-datepicker__day.react-datepicker__day--in-range {
  background-color: #F28820;
}

.search-datepicker-style .react-datepicker__input-container input {
  background-color: rgb(244 247 254);
  display: block;
  height: 100%;
  width: 100%;
  font-size: 0.875rem;
  line-height: 1.25rem;
  font-weight: 500;
  background-color: rgb(244 247 254);
  outline: 2px solid transparent;
  outline-offset: 2px;
  border-radius: 9999px;
  color: rgb(27 37 75);
  padding: 0.75rem;
}

.search-datepicker-style .react-datepicker__input-container input:focus {
  outline: 2px solid transparent;
  outline-offset: 2px;
}

.rbc-calendar .rbc-event {
  background-color: #f5ac63;
}

.rbc-time-column .rbc-timeslot-group:nth-of-type(-n+9) {
  /* hide extra time slots from calendar view */
  display: none;
}

.rbc-calendar .rbc-event.rbc-selected {
  background-color: #E07417 !important;
}

.rbc-calendar .rbc-agenda-table tr{
 background-color: transparent !important;
}

.dark-custom-calendar .rbc-toolbar .rbc-btn-group button {
  background: white;
}

.dark-custom-calendar .rbc-toolbar .rbc-btn-group button:hover {
  background: rgba(163, 162, 162, 0.882);
}

.rbc-calendar .rbc-toolbar .rbc-btn-group button.rbc-active {
  background: #F28820;
  color: white
}

.dark-custom-calendar .rbc-off-range-bg{
  background: #424242;
}

.rbc-calendar .rbc-show-more {
  color: #F28820;
  background: transparent;
}

.dark .react-datepicker__input-container input {
  background-color: transparent;
  color: white;
}



  /* scrollbar */
  ::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }
  
  ::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    -webkit-border-radius: 10px;
    border-radius: 10px;
  }
  
  ::-webkit-scrollbar-thumb {
    -webkit-border-radius: 10px;
    border-radius: 10px;
    background: rgba(255, 255, 255, 0.3);
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
  }
  
  ::-webkit-scrollbar-thumb:window-inactive {
    background: rgba(255, 255, 255, 0.3);
  }

